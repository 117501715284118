import * as React from "react"
import Layout from "../components/layout"
import * as styles from "./index.module.scss"

const ReadLink = ({ text, link, type }) => {
  return (
    <div>
      <a target="_blank" href={link}>
        {text}
      </a>{" "}
    </div>
  )
}

class Index extends React.Component {
  render() {
    const books = [
      { title: "The Peregrine (Baker)" },
      { title: "Survival in Auschwitz (Levi)" },
      { title: "On Earth We're Briefly Gorgeous (Vuong)" },
      { title: "anyone lived in a pretty how town (Poem, Cummings)" },
      { title: "The Death of Ivan Ilych (Tolstoy)" },
      { title: "The Wanderer (Exeter Book)" },
      { title: "How Do You Live? (Genzaburo)" },
      // { title: "On Writing (King)" },
      // { title: "Autobiography of a Face (Grealy)" },
      { title: "The Sailor Who Fell from Grace with the Sea (Mishima)" },
      { title: "The Bell Jar (Plath)" },
      { title: "Strangers Drowning (MacFarquhar)" },
      { title: "Shantung Compound (Gilkey)" },
      // { title: "Man's Search for Meaning (Frankl)" },
      // { title: "Veronica Decides to Die (Coelho)" },
      // https://www.poetryfoundation.org/poems/49451/a-song-on-the-end-of-the-world
      // https://poets.org/poem/eating-alone
      // https://www.poetryfoundation.org/poems/46565/ozymandias
      { title: "The Story of a Head That Fell Off (Akutagawa)" },
    ]
    return (
      <Layout title="linhao">
        <div className={styles.index}>
          <p>
            I'm currently building{" "}
            <a target="_blank" href="https://www.formation.bio/">
              Formation Bio
            </a>{" "}
            with an amazing team. Our mission is to bring treatments to patients
            faster and more efficiently by reimagining what it means to be a
            pharma company.
          </p>
          <p>
            I run{" "}
            <a target="_blank" href="https://foundation649.com/">
              Foundation 649
            </a>
            , a college scholarship for AAPI students.
          </p>
          <p>
            I enjoy photography - here are some shots from a <a href="/nepal">
              trip to Nepal
            </a>, and some portraits of my friends:
            <br />
            <a href="/evan-stephanie">
              Evan and Stephanie
            </a> <br />
            Jason and Casey: {" "}
            <a href="/jason-casey">
              Point Reyes
            </a>{" | "}
            <a href="/jason-casey-city-hall">
              City Hall
            </a> <br />
            <a href="/will-jenna">
              Will and Jenna
            </a> <br />
            <a href="/doulos-felicia">
              Doulos and Felicia
            </a> <br />
          </p>
          <p>
            Some of my favorite reads:<br />
            {books.map(book => (
              <div>{book.title}</div>
            ))}
          </p>
          <p>
            I love meeting builders, creators, and writers. DM me if you want to chat.
            <br />
            {/* <a target="_blank" href="https://www.instagram.com/lintaho/">
              Instagram
            </a>
            {" | "} */}
            <a target="_blank" href="https://twitter.com/Lintaho/">
              Twitter
            </a>
            {" | "}
            <a target="_blank" href="https://www.linkedin.com/in/linhaozhang/">
              Linkedin
            </a>
            {" | "}
            <a target="_blank" href="https://unsplash.com/@lintaho">
              Unsplash
            </a>
          </p>
        </div>
      </Layout>
    )
  }
}

export default Index
